import React from 'react'
import '../home/ahomecss/Whywe.css'

import grocery from '../images/grocerywhywe.jpg'

import threepersonwhywe from '../images/threepersonwhywe.png'
import fruits from '../images/fruits.png'

const Whywe = () => {
  return (
    <div>
      
<div className='maindiv-whywe'>

    <div className='cards-div-whywe'>
{/* ///// */}
<div className='firstcard-section-whywe'>

<div className='text-firstcard-whywe'>
    <h1>Why we <br className='brnone-whywe' /> Stand out</h1>
    <p>A to Z's <br className='brnone-whywe' /> Hidden Gems</p>
</div>

<div className='img-firstcard-whywe'>
<img src={grocery} alt="" />
</div>


<div className='text-thridcard-whywe'>
    <button>Know more</button>
    <h1>In House<br className='brnone-whywe'/> spices & pulses</h1>
    <p>Our in-house putses and spices are carefully se lected to ensure you get the finest quality and freshest flavors. When you shop with us, you're bringing home ingredients that make every meal extraordinary.</p>
</div>

</div>


    </div>

<div className='second-sectioncard-main-div'>

<div className='flex-div-section2'>
    
    <div>
  

<div className='img-firstcard-whywe'>
<img src={threepersonwhywe} alt="" />
</div>


<div className='text-thridcard-whywe'>
    <button>Know more</button>
    <h1>Warmth in Every<br className='brnone-whywe'/>Welcome</h1>
    <p>At A22. expect a friendly smile and a helping hand from people who know you by name and treat you like family. We're here to make every visit en- joyable.</p>
</div>
    </div>

    {/* card 2 */}
    <div>
   

<div className='img-firstcard-whywe'>
<img src={fruits} alt="" />
</div>


<div className='text-thridcard-whywe'>
    <button>Know more</button>
    <h1>Fresh Fruits &<br className='brnone-whywe'/>Vegetables</h1>
    <p>From crisp vegetables to juicy fruits, our produce section is a rainbow of freshness. We source and stock the best so you can enjoy nature's bounty right at your dining table. Because when it's fresh It tastes better.</p>
</div>
    </div>

</div>

{/* card men start */}
<div className='cardmen-whywe'>


<div>
<h1>Door Delivery</h1>
<p>
Busy day? No problami Our door delivery service brings the store to your doorstep. Whether it's a last-minute grocery run or a big shopping spree we li make sure your essentials amve right where you need them
</p>

</div>

</div>


{/* card men end */}
</div>

   

  

</div>

    </div>
  )
}

export default Whywe
