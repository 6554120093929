import React from 'react'
import '../home/ahomecss/Fotter.css'
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';

const Fotter = () => {
  return (
    <div className='main-div-fotter'>

        <div className='first-section-fotter'>
            <div className='first-div-fotter'>
                <p>Phone: 0491 255 2245</p>
                <p>Email: info@a2zsupermarkets.in</p>
                <p>Social Media: Facebook, Twitter, Instagram</p>
            <hr />
            <p className='head-question-fotter'>Head Quarters</p>
            <p>A to Z Supermarket, Shop no-2, Olavakkode Dhoni Road, Railway
            Colony,</p>
            <p>Hemambika Nagar, Puthuppariyaram, Palakkad, Kerala 678009</p>
            </div>

            <div className='two-div-fotter'>
<div className='team-div-fotter'>
    <p>Team</p>
    <p>Blog</p>
    <p>Store</p>
</div>

<div className='second-team-div-fotter'>
    <p>Subscribe to our news letter</p>
    
    <div>
        <input type="text" />
        <button>Subscribe</button>
    </div>

</div>
            </div>

        </div>




        <div className='icon-div-fotter'>
            <div>
                <FacebookIcon className='icon'></FacebookIcon>
                <InstagramIcon className='icon'></InstagramIcon>
                <XIcon className='icon'></XIcon>
                <WhatsAppIcon className='icon'></WhatsAppIcon>
          
          
          
            </div>
            
        </div>
      <p className='fotter-last-p'>Drop by, explore our offerings and see how we're making everyday shopping a delightful experience.</p>
        </div>
  )
}

export default Fotter
