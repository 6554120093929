import React from 'react'
import '../home/ahomecss/Ourpromis.css'


const Ourpromis = () => {
  return (
    <div>



      <div className='main-our-promis-div'>


{/* first card start */}
        <div className='our-promis-first-card'>
<div className='img-div-ourpromis-card'>

</div>

<div className='content-card-our-promis'>
  <h1>Our Promise to You!</h1>
  <h4>25 STORES IN 36 MONTHS</h4>
  <p>We aim to be the trusted partner that makes your daily shopping easier, ensuring that you have everything you need to make your home complete with quality products, friendly service, and convenience.</p>
</div>
        </div>
   {/* first card end */}  

{/* second card start */}
<div className='our-promis-second-card'>

<div className='content-second-card-our-promis'>
  <h1>Scaling Up, Staying Close</h1>
  <h4>25 STORES IN 36 MONTHS</h4>
  <p>To expand across India while keeping our personal touch, we aim to be the leading necessity store known for quality and exceptional service. As we open new franchises, we're com- mitted to deepening our connection with customers and communities.</p>
</div>
<div className='second-img-div-ourpromis-card'>

</div>


        </div>
   {/* second card end */}  

      </div>
 
    </div>
  )
}

export default Ourpromis
