import React, { useEffect, useState, useRef, useCallback } from 'react';
import '../home/ahomecss/Branch.css';

const Branch = () => {
  const [branches, setBranches] = useState(0);
  const [customers, setCustomers] = useState(0);
  const [products, setProducts] = useState(0);
  const [hasAnimated, setHasAnimated] = useState(false); // Flag to check if animation has run

  const branchRef = useRef(null);

  const handleScroll = useCallback(() => {
    const rect = branchRef.current.getBoundingClientRect();
    if (rect.top <= 1000 && !hasAnimated) { // Adjust 1000 to your desired scroll position
      // Reset values to 0
      setBranches(0);
      setCustomers(0);
      setProducts(0);
      // Trigger animations
      animateValue(setBranches, 5, 10000);
      animateValue(setCustomers, 6500000, 10000);
      animateValue(setProducts, 40000, 10000);
      setHasAnimated(true); // Set the flag to true after animation starts
    }
  }, [hasAnimated]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check in case the element is already in view

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  const animateValue = (setter, target, duration) => {
    let start = 0;
    const range = target - start;
    const increment = range / (duration / 60); // Update more frequently for smooth transition

    const updateValue = () => {
      start += increment;
      if (start >= target) {
        setter(target);
      } else {
        setter(Math.ceil(start));
        setTimeout(updateValue, 16); // Approx 60 FPS (16.67ms per frame)
      }
    };

    updateValue();
  };

  const formatValue = (value) => {
    if (value >= 1000000) {
      return `${(value / 1000000).toFixed(1)}M+`;
    } else if (value >= 1000) {
      return `${(value / 1000).toFixed(0)}K+`;
    } else {
      return Math.ceil(value);
    }
  };

  const [startAnimation, setStartAnimation] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const triggerPoint = 1500; // The scroll trigger point

      if (window.scrollY >= triggerPoint) {
        setStartAnimation(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  const [animateTimeline, setAnimateTimeline] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const triggerPoint = 2400; // Adjust this based on your content
      console.log('ScrollY:', window.scrollY); // Log current scroll position
      if (window.scrollY >= triggerPoint) {
        setAnimateTimeline(true);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className='count-ranch-main-div' ref={branchRef}>
      <div className='count-section-black'>
        <div>
          <h1 className="count-number">0{formatValue(branches)}</h1>
          <h3>Branches</h3>
        </div>
        <div>
          <h1 className="count-number">{formatValue(customers)}</h1>
          <h3>Happy customers</h3>
        </div>
        <div className='product-count-div'>
          <h1 className="count-number">{formatValue(products)}</h1>
          <h3>Products</h3>
        </div>
      </div>

      {/* section 2 */}
      <div className='section-two-branch-div'>
        <h1>Our Journey Together</h1>
        <p>Over the past 40 years, your trust has been our greatest ally in expanding from a little shop to a network of six supermarkets. As we've grown, our dedication to you has only deepened.</p>
      </div>
      {/* section 2 End */}

      {/* section 3 Start */}
      <div className={`historydate-div-sec-three ${startAnimation ? 'start-animation' : ''}`}>
        {/*  date-secation-main-div START*/}
        <div className='date-secation-main-div'>
          <div>
            <h1>1980</h1>
            <h4>Ancestral Store, <br /> Akathethara</h4>
          </div>

          
          <div>
            <h1>2005</h1>
            <h4>First Retail Leap</h4>
            
          </div>

          <div>
            <h1>2011</h1>
            <h4>Renovating old shop</h4>
            
          </div>


          <div>
            <h1>2014</h1>
            <h4>3rd supermarket</h4>
          
          </div>


          <div>
            <h1>2018</h1>
            <h4>Our 4th Franchise</h4>
          
          </div>

          <div>
            <h1>2021</h1>
            <h4>5th Edition</h4>
            
          </div>

          
        </div>
        {/* date-secation-main-div END*/}
{/* line div start */}



<div class="timeline">



  <div class="timeline-dot"></div>
  <div class="timeline-line"></div>
  <div class="timeline-dot " ></div>
  <div class="timeline-line"></div>
  <div class="timeline-dot"></div>
  <div class="timeline-line"></div>
  <div class="timeline-dot"></div>
  <div class="timeline-line"></div>
  <div class="timeline-dot"></div>
  <div class="timeline-line"></div>
  <div class="timeline-dot"></div>
  <div class="timeline-line"></div>
</div>

{/* line div end */}

<div className='p-branch'>
  <p>Where it all begins</p>
  <p>Ovakkode Railway colony</p>
  <p>Olvakkode Railway colony</p>
  <p>Expanding our wings</p>
  <p>Sutanper joins family</p>
  <p>Five and tiviving</p>

</div>
      </div>



      {/* PHONE SCREEN SECTION  */}
      <div className={`phone-historydate-div-sec-three ${animateTimeline ? 'start-animation' : ''}`}>
        {/*  date-secation-main-div START*/}
        <div className='phone-date-secation-main-div'>
          <div>
            <h1>1980</h1>
            <h4>Ancestral Store, <br /> Akathethara</h4>
            <p>Where it all begins</p>
 
          </div>

<div>
<div class="timelinephone-line"></div>
  <div class="timelinephone-dot"></div>

</div>
          
          <div>
            <h1>2005</h1>
            <h4>First Retail Leap</h4>
            <p>Ovakkode Railway colony</p>
 
 
          </div>

          <div>
          <div class="timelinephone-line"></div>
  <div class="timelinephone-dot"></div>
 
</div>
          <div>
            <h1>2011</h1>
            <h4>Renovating old shop</h4>
            <p>Ovakkode Railway colony</p>
          </div>
<div>
<div class="timelinephone-line"></div>
  <div class="timelinephone-dot"></div>
  
</div>

          <div>
            <h1>2014</h1>
            <h4>3rd supermarket</h4>
            
            <p>Expanding our wings</p>
          
          </div>
<div>
<div class="timelinephone-line"></div>
  <div class="timelinephone-dot"></div>

</div>

          <div>
            <h1>2018</h1>
            <h4>Our 4th Franchise</h4>
          
            <p>Sutanper joins family</p>
          </div>
<div>
<div class="timelinephone-line"></div>
  <div class="timelinephone-dot"></div>

</div>
          <div>
            <h1>2021</h1>
            <h4>5th Edition</h4>
            <p>Five and tiviving</p>
          </div>

          
        </div>
        {/* date-secation-main-div END*/}


      </div>
      
      {/* section 3 End */}
    </div>
  );
};

export default Branch;
