import React, { useEffect, useState } from 'react';
import '../home/ahomecss/Nav.css';
import logo from '../images/atozlogo.png';
import MenuIcon from '@mui/icons-material/Menu';
import locationiconimg from '../images/locationimg.png';
import CloseIcon from '@mui/icons-material/Close';

const Nav = () => {
  const [isFixed, setIsFixed] = useState(false);
  const [menuVisible, setMenuVisible] = useState(false);
  const [menuAnimating, setMenuAnimating] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 30) {
        setIsFixed(true);
      } else {
        setIsFixed(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.nav-btn-div') && !event.target.closest('.menu-items-btn-div')) {
        handleCloseMenu();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const toggleMenuVisibility = () => {
    if (menuVisible) {
      handleCloseMenu();
    } else {
      setMenuVisible(true);
      setMenuAnimating(false);
    }
  };

  const handleCloseMenu = () => {
    setMenuAnimating(true);
    setTimeout(() => {
      setMenuVisible(false);
      setMenuAnimating(false);
    }, 300); // Match the duration of the slideOut animation
  };

  return (
    <div>
      <div className='nav-bar-div'>
        <div>
          <img className='logo-img-nav' src={logo} alt="Logo" />
        </div>

        <div className='nav-btn-div'>
          <button className='locationbtn'>
            <img className='locationimg' src={locationiconimg} alt="Location Icon" /> Locate Us
          </button>
          <button
            className={`button-menu-nav ${isFixed ? 'fixed' : ''}`}
            onClick={toggleMenuVisibility}
          >
            <MenuIcon />
          </button>
        </div>
      </div>

      <div className={`menu-items-btn-div ${menuVisible ? (menuAnimating ? 'hidden' : 'visible') : ''}`}>
        <div>
          <button onClick={handleCloseMenu} className='close-btn'>
            <CloseIcon />
          </button>
        </div>
        <button onClick={handleCloseMenu}>Our Commitments</button>
        <button onClick={handleCloseMenu}>Media</button>
        <button onClick={handleCloseMenu}>Branches</button>
        <button onClick={handleCloseMenu}>Franchise</button>
        <button onClick={handleCloseMenu}>Contact</button>
      </div>
    </div>
  );
};

export default Nav;
