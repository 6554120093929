import React from 'react';
import '../home/ahomecss/Feedback.css';

const Feedback = () => {
  const images = [
    "https://placehold.co/350x200",
    "https://placehold.co/350x200",
    "https://placehold.co/350x200",
    "https://placehold.co/350x200",
    "https://placehold.co/350x200",
  ];

  return (
    <div className='maindiv-feedback'>

<div className='feedback-h1-div'>
<div></div>
<div>
  <div className='secondnd-div-inner-fedback'>
<h1>Feedback Aisle</h1>
  </div>
</div>
<div></div>
</div>

      <div className='firstsectionimg'>
        {images.concat(images).map((src, index) => (
          <img key={index} src={src} alt={`Slide ${index}`} />
        ))}
      </div>
      <div className='secondsectionimg'>
        {images.concat(images).map((src, index) => (
          <img key={index} src={src} alt={`Slide ${index}`} />
        ))}
      </div>
    </div>
  );
}

export default Feedback;
