
import './App.css';
import Nav from './home/Nav';
import Hero from './home/Hero';
import Whywe from './home/Whywe';
import Branch from './home/Branch';
import Ourpromis from './home/Ourpromis';
import Feedback from './home/Feedback';
import Fotter from './home/Fotter';

function App() {
  return (
    <div className="App">
     <Nav></Nav>
     <Hero></Hero>
     <Whywe></Whywe>
     <Branch></Branch>
     <Ourpromis></Ourpromis>
     <Feedback></Feedback>
     <Fotter></Fotter>
    </div>
  );
}

export default App;
