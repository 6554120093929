import React from 'react'
import '../home/ahomecss/Hero.css'
import heroimg from '../images/heroimage.png'
const Hero = () => {
  return (
    <div>

        <div className='hero-maindiv'>

            <div className='text-div-hero'>
<h1>From A to Z <br />
<span>Everything You Need</span></h1>
<p>Welcome to A to Z Supermarket, your one-stop destination for everything you need!</p>
            </div>
            <div className='img-div-hero'>
               
                <img src={heroimg} alt="" />
                <div className='color-div-baground'></div>
            </div>

        </div>
      
    </div>
  )
}

export default Hero
